import React, { useEffect, useState } from 'react'
import Layout from '../components/Layout'
import ZoneDate from '../components/timezone-components/zone-date'
import ZoneDates from '../components/timezone-components/zone-dates'

import TimezoneConverter from '../components/timezone-components/timezone-converter'

const TimezoneConverterPage = props => {
    	var [date, setDate] = useState(new Date())
		useEffect(()=>{
			var timer = setInterval(()=> setDate(new Date()), 1000)

			return function cleanup(){
				clearInterval(timer)
			}
    	})
    if (typeof window !== 'undefined') {
		const getPathName = window.location.pathname;
		const defaultTimezone = [  
			{key: 37, name: ""},
			{key: 16, name: ""},
			{key:12, name:""}, 
			{key:10, name:""}, 
			{key:5, name:""}, 
		]

		return(
			<Layout
				langKey="en"
				slug="/"
			>
				{/* <section className="hero is-fullheight"> */}
					<div
						className="content"
						style={{paddingBottom: "1.5rem"}}
						>	
					</div>
					<div
						style={{
							textAlign: 'center',
							margin: "2rem"
						}}>
						<p className='is-size-9 has-text-weight-bold'>
							{props.title}
						</p>
						<div className={"table-container"}>
							<table className="table is-striped is-fullwidth">
								<thead>
								<tr>
									<th style={{backgroundColor: "black", color: "white"}}>
											General
									</th>
									<th style={{backgroundColor: "black", color: "white"}}>
											Alberta
									</th>
									<th style={{backgroundColor: "black", color: "white"}}>
											Panama
									</th>
								</tr>
								</thead>
							
								<tfoot>
								<tr>
									<th style={{backgroundColor: "black", color: "white"}}>
											General
									</th>
									<th style={{backgroundColor: "black", color: "white"}}>
											Alberta
									</th>
									<th style={{backgroundColor: "black", color: "white"}}>
											Panama
									</th>
								</tr>
								</tfoot>
							<tbody>
								<tr>
									<td style={{color: "blue"}}>
										<a href={`${getPathName}?&timezone=37,&timezone=16,&timezone=12,&timezone=10,&timezone=5,`}>
											North America Primary Time Zones
										</a>
									</td>
									<td style={{color: "blue"}}>
										<a href={`${getPathName}?&timezone=37,&timezone=83,PhST%2F%20HK%2F%20China%20&timezone=15,Panama%20Standard%20Time`}>
											PhST/HK/China time vs Alberta Canada time
										</a>
									</td>
									<td style={{color: "blue"}}>
										<a href={`${getPathName}?&timezone=83,PhST%2F%20HK%2F%20China&timezone=37,&timezone=10,`}>
											PhST/HK/China time vs Panama time
										</a>
									</td>
								</tr>
								<tr>
									<td style={{color: "blue"}}>
									</td>
									<td style={{color: "blue"}}>
										<a href={`${getPathName}?&timezone=37,&timezone=75,&timezone=10,`}>
											India time vs Alberta Canada time
										</a>
									</td>
									<td style={{color: "blue"}}>
										<a href={`${getPathName}??&timezone=37,&timezone=75,India%20Standard%20Time&timezone=15,Panama%20Standard%20Time`}>
											India time vs Panama time
										</a>
									</td>
								</tr>
								<tr>
									<td style={{color: "blue"}}>
										
									</td>
									<td style={{color: "blue"}}>
										<a href={`${getPathName}?&timezone=37,&timezone=11,Costa%20Rica%20Standard%20Time&timezone=10,`}>
											Costa Rica vs Alberta Canada time
										</a>
									</td>
									<td style={{color: "blue"}}>
										<a href={`${getPathName}??&timezone=37,&timezone=11,Costa%20Rica&timezone=15,Panama%20Standard%20Time`}>
											Costa Rica vs Panama time
										</a>
									</td>
								</tr>
									<tr>
									<td style={{color: "blue"}}>
										
									</td>
									<td style={{color: "blue"}}>
										<a href={`${getPathName}?&timezone=37,&timezone=15,Panama%20Standard%20Time&timezone=10,`}>
											Panama EST -5:00 vs Alberta Canada time	
										</a>
									</td>
									<td style={{color: "blue"}}>
										
									</td>
								</tr>
							</tbody>
							</table>

						</div>
					</div>
					<TimezoneConverter defaultTimezone={defaultTimezone} hideURLGenerator={true}></TimezoneConverter>
				{/* </section> */}
			
			</Layout>  
		)

	}
	else return null


    
 
}

export default TimezoneConverterPage
